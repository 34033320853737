import * as React from 'react'

import * as S from './sections'
import { ITemplates, SectionsMap } from './types'

const errorStyle = {
  color: '#EE3465',
  padding: '6em',
  textAlign: 'center',
}

const SectionsTemplates: React.FC<{ data: ITemplates }> = ({ data }) => {
  if (!Array.isArray(data)) {
    return (
      <div
        style={errorStyle as any}
        dangerouslySetInnerHTML={{
          __html: `<strong>No content found in \`sections\`.</strong> <br/> Check your .yml file`,
        }}
      />
    )
  }

  return (
    <>
      {data.map(({ type, content }) => {
        const Component = (S as SectionsMap)[type]

        if (!Component) {
          return (
            <div
              style={errorStyle as any}
              dangerouslySetInnerHTML={{
                __html: `You are trying to render ${type} component, <br/><strong>but this component doesn't exist.</strong> <br/> Check your .yml file`,
              }}
            />
          )
        }

        if (!content) {
          return (
            <div
              style={errorStyle as any}
              dangerouslySetInnerHTML={{
                __html: `You are trying to render ${type} component, <strong><br/>but the content was not found.</strong> <br/> Check your .yml file`,
              }}
            />
          )
        }

        return <Component key={type} data={content} />
      })}
    </>
  )
}

export default SectionsTemplates
