import styled from '../../styled'

export const VideoCard = styled.div`
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  img {
    display: block;
  }

  a {
    position: relative;
    display: block;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
    }
  }
`

export const VideoBody = styled.div`
  padding: 1.5em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 3;
`
