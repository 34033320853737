import * as React from 'react'

import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image, ImageRounded } from '../../shared/Image'
import Link from '../../shared/Link'
import { Caption, Text } from '../../shared/Typography'
import styled, { css } from '../../styled'
import { IPicturesWithCopy } from '../types'
import { isUrlExternal } from '../../../utils/isUrlExternal'

export const Texture = styled.div`
  ${({ theme: { breakpoints } }) =>
    css`
      position: absolute;
      bottom: -5em;
      left: 5em;
      right: -5em;
      z-index: -2;

      @media screen and (max-width: ${breakpoints[0]}) {
        display: none;
      }
    `};
`

const errorStyle = {
  color: '#EE3465',
  padding: '6em',
  textAlign: 'center',
}

export default function PicturesWithCopy({ data }: IPicturesWithCopy) {
  if (!Array.isArray(data.images) || data.images.length === 0) {
    return (
      <div
        style={errorStyle as any}
        dangerouslySetInnerHTML={{
          __html: `<strong>Image not found</strong> <br/> Check your .yml file`,
        }}
      />
    )
  }

  const hasFirstImage = data.images[0]
  const hasSecondImage = data.images[1]
  const imageRight = data.imagePosition === 'right'

  const boxStyle = {
    mb: ['1.5em', '-7.5em'],
    mt: hasSecondImage ? null : ['0', '12em'],
    pr: ['0', '1.5em'],
    width: ['100%', '60%', '50%'],
    zIndex: 2,
  }

  const getArrowTypeIfLinkIsExternalOrInternal = (link: string) => {
    return isUrlExternal(link) ? 'arrowNortheast' : 'arrow'
  }

  const contentCard = (
    <Box {...boxStyle}>
      <Card p={[null, '2em']}>
        <Caption mb="1rem">{data.title}</Caption>
        <Text color="greyMedium">{data.description}</Text>

        {data.ctaLink && data.cta && (
          <Link
            mt="1.5rem"
            color="blue"
            to={data.ctaLink}
            withIcon={getArrowTypeIfLinkIsExternalOrInternal(data.ctaLink)}
          >
            {data.cta}
          </Link>
        )}
      </Card>
    </Box>
  )

  const images = (
    <>
      <Box width={['100%', '40%', '50%']}>
        {hasFirstImage && (
          <Box
            width={['100%', 'calc(100% + 8em)']}
            ml={imageRight ? null : ['0', '-8em']}
            mb="1.5em"
          >
            <ImageRounded path={hasFirstImage.image} alt={data.title} />
          </Box>
        )}

        {hasSecondImage && (
          <Box width={['100%', '264px']}>
            <ImageRounded path={hasSecondImage.image} alt={data.title} />
          </Box>
        )}
      </Box>

      {!hasSecondImage && (
        <Texture>
          <Image path="homePageHubTexture" alt={data.title} />
        </Texture>
      )}
    </>
  )

  const leftImage = [contentCard, images]
  const rightImage = [images, contentCard]

  return (
    <Container mb={['5em', '14em']}>
      <Flex alignItems="center" flexWrap="wrap">
        {imageRight ? rightImage : leftImage}
      </Flex>
    </Container>
  )
}
