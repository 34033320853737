import { graphql } from 'gatsby'
import * as React from 'react'

import Newsletter from '../components/Newsletter'
import SectionsTemplates from '../components/SectionsTemplates'
import { ITemplates } from '../components/SectionsTemplates/types'
import SEO from '../components/SEO'
import Layout from '../layouts'

interface ITemplate {
  data: {
    pagesYaml: { sections: ITemplates }
  }
  pageContext: {
    slug: string
  }
}

const AutoTemplate: React.FC<ITemplate> = ({
  data: { pagesYaml },
  pageContext: { slug },
}) => {
  return (
    <Layout>
      <SEO />
      <SectionsTemplates data={pagesYaml.sections} />
      <Newsletter />
    </Layout>
  )
}

export default AutoTemplate

export const query = graphql`
  query AutoTemplate($slug: String!) {
    pagesYaml(path: { eq: $slug }) {
      sections {
        type
        content {
          title
          description
          cta
          ctaLink
          imagePosition
          images {
            image
            alt
            description
            link
          }
          # Cards #
          contact {
            title
            contacts {
              link
              text
              title
            }
          }
          publicKey {
            title
            text
            value
          }
          fingerprint {
            title
            text
            value
          }
          #  SocialMedia  #
          socialMediaSites {
            label
            src
          }
          # HeroNoBackground #
          listLinks {
            cta
            ctaLink
          }
        }
      }
    }
  }
`
