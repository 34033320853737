import styled, { css } from '../../styled'

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`

export const Inner = styled.div`
  transition: all ${({ theme: { transition } }) => transition.ease(600)};
  white-space: nowrap;
  display: block;
`

export const Slide = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const DotLists = styled.div`
  text-align: center;
`

export const Dot = styled.button<{ current: boolean }>`
  background: ${({ theme: { colors } }) => colors.grayscale.light};
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 0 0.3em;
  transition: all ${({ theme: { transition } }) => transition.ease()};

  ${({ theme: { colors }, current }) =>
    current &&
    css`
      background: ${colors.grayscale.black};
    `};
`
