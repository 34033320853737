import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { ImageRounded } from '../../shared/Image'
import { Caption, Description, FootNote, Text } from '../../shared/Typography'
import Link from '../../shared/Link'
import { IImages, IVideoThumbs } from '../types'
import { VideoBody, VideoCard } from './parts'

const imageIcon = require('../../../images/icons/video.svg')

export default function renderVideoSoftware({ data }: IVideoThumbs) {
  const cols = ['100%', '50%']
  const padd = ['0', '1em']

  if (Array.isArray(data.images) && data.images.length > 1) {
    return (
      <Container mb={['2em', '4em']}>
        <Flex flexWrap="wrap">
          <Box width={cols} pr={padd}>
            {renderVideoCard(data.images[0])}
            <Description
              p={[0, '4em']}
              my={['2em', 0]}
              color="greyMedium"
              mb="1rem"
            >
              {data.description}
            </Description>

            {data.textLink && data.link && (
              <Flex pl={[0, '5em']} mt={[0, '-3em']} mb="4em">
                <Box alignSelf="center" pr="0.4em">
                  <img src={imageIcon} alt={data.description} />
                </Box>
                <Link color="blue" to={data.link}>
                  {data.textLink}
                </Link>
              </Flex>
            )}
          </Box>

          <Box
            mb={['1em', 0]}
            mt={['', '13em']}
            order={[-1, 0]}
            pl={padd}
            width={cols}
          >
            {renderVideoCard(data.images[1])}
          </Box>
        </Flex>
      </Container>
    )
  }

  return null
}

function renderVideoCard({ image, alt, description, link }: IImages) {
  return (
    <VideoCard>
      <a key={alt} href={link} target="_blank" rel="noreferrer">
        <VideoBody>
          <Text as="div" color="blue" mb=".5em">
            <Flex alignItems="center">
              <Box mr=".5em" mt="-.2em">
                <img src={imageIcon} alt="Play video" />
              </Box>
              <span>Play video</span>
            </Flex>
          </Text>
          <Caption mb=".2em" color="white">
            {alt}
          </Caption>
          <FootNote color="greyLight">{description}</FootNote>
        </VideoBody>

        <ImageRounded path={image} alt={alt} />
      </a>
    </VideoCard>
  )
}
