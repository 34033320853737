import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import getProjectIcons from '../../shared/Helpers/getProjectIcons'
import HeroOverlay from '../../shared/HeroOverlay'
import { Image } from '../../shared/Image'
import { Caption, Title } from '../../shared/Typography'
import { IHero } from '../types'

export default function renderHero({ data }: IHero) {
  const cols = ['100%', '50%']
  const padd = ['0', null, '4em']
  const icon = getProjectIcons(data.title)

  return (
    <HeroOverlay>
      <Container>
        <Flex flexWrap="wrap" px={[null, '0', '6em']}>
          <Box pr={padd} width={cols} mt="4em">
            <Flex>
              {icon && (
                <Box mt="-.2em" mr="1em">
                  <img alt={data.title} src={icon} />
                </Box>
              )}
              <Title mb="1rem">{data.title}</Title>
            </Flex>
            <Caption color="greyMedium" mb="1rem">
              {data.description}
            </Caption>
          </Box>

          <Box width={cols}>
            {data.images &&
              data.images.map(({ image, alt }) => (
                <Image path={image} alt={alt} />
              ))}
          </Box>
        </Flex>
      </Container>
    </HeroOverlay>
  )
}
