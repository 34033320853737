import * as React from 'react'

import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Caption, Text } from '../../shared/Typography'
import { ICards } from '../types'
import Link from '../../shared/Link'
import Clipboard from 'react-clipboard.js'
import { ellipsisString } from '../../../utils/ellipsisString'

export default function RenderCards({ data }: ICards) {
  const cols = ['100%', 'calc((100% / 3) - 12px)']
  const stringSize = 57

  return (
    <Container mb={['3.75em', '7em']}>
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Box width={cols}>
          <Card>
            <Flex>
              <Caption mb="1rem">{data.contact.title}</Caption>
            </Flex>
            {data.contact.contacts.map(({ title, text, link }) => {
              return (
                <Flex
                  key={title}
                  flexDirection={['row', 'column', 'column', 'row']}
                >
                  {title &&
                    (link !== null && link ? (
                      <Link color="blue" to={link} title={title} mr="0.25em">
                        {title}
                      </Link>
                    ) : (
                      <Text color="blue" mr="0.25em">
                        {title}
                      </Text>
                    ))}
                  <Text color="grey">{text} </Text>
                </Flex>
              )
            })}
          </Card>
        </Box>
        <Box width={cols}>
          <Card>
            <Caption mb="1rem">{data.fingerprint.title}</Caption>
            <Clipboard data-clipboard-text={data.fingerprint.value}>
              <Text color="blue">{data.fingerprint.text}</Text>
            </Clipboard>
            <Text color="grey">{data.fingerprint.value}</Text>
          </Card>
        </Box>
        <Box width={cols}>
          <Card>
            <Caption mb="1rem">{data.publicKey.title}</Caption>
            <Clipboard data-clipboard-text={data.publicKey.value}>
              <Text color="blue">{data.publicKey.text}</Text>
            </Clipboard>
            <Text
              color="grey"
              style={{
                wordWrap: 'break-word',
              }}
            >
              {ellipsisString(data.publicKey.value, stringSize)}
            </Text>
          </Card>
        </Box>
      </Flex>
    </Container>
  )
}
