import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Image } from '../../shared/Image'
import { IGallery } from '../types'
import { Dot, DotLists, Inner, Slide, Wrapper } from './parts'

interface IState {
  index: number
  intervalId: number | undefined | NodeJS.Timer
}

class Gallery extends React.Component<IGallery, IState> {
  state = {
    index: 0,
    intervalId: undefined,
  }

  componentDidMount() {
    this.setTimer()
  }

  setTimer = (): void => {
    const intervalId = setInterval(this.goNext, 5000)

    return this.setState({ intervalId })
  }

  removeTimer = (): void => {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId)
    }
  }

  goNext = () => {
    const {
      data: { images },
    } = this.props

    if (Array.isArray(images) && images.length) {
      const { index: currentIndex } = this.state
      const index = (currentIndex + 1) % images.length

      this.setState({ index })
    }
  }

  componentWillUnmount() {
    this.removeTimer()
  }

  goTo = (index: number) => {
    this.removeTimer()
    this.setTimer()
    this.setState({
      index,
    })
  }

  renderDots = () =>
    this.props.data.images.map((item, index) => (
      <Dot
        key={item.alt}
        current={index === this.state.index}
        onClick={() => this.goTo(index)}
      />
    ))

  render() {
    const {
      data: { images },
      ...props
    } = this.props
    const { index } = this.state

    if (Array.isArray(images) && images.length) {
      return (
        <Container px={['0', '6em']} mb={['2em', '5em']}>
          <Wrapper {...props}>
            <Inner
              style={{
                transform: `translateX(${(index % images.length) * -100}%)`,
              }}
            >
              {images.map(({ image, alt }) => (
                <Slide key={alt}>
                  <Image path={image} alt={alt} />
                </Slide>
              ))}
            </Inner>

            <DotLists>{this.renderDots()}</DotLists>
          </Wrapper>
        </Container>
      )
    }

    return null
  }
}

export default Gallery
