import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import Link from '../../shared/Link'
import { Description, SubTitle } from '../../shared/Typography'
import { ISocialMedia } from '../types'
import styled from '../../styled'
import getSocialIcons from '../../shared/Helpers/getSocialIcons'

const SocialLink = styled(Link).attrs({ ghost: true })`
  margin-right: 1.3em;
  color: ${({ theme: { colors } }) => colors.grayscale.default};
  transition: color ${({ theme: { transition } }) => transition.ease()};

  &:hover {
    color: ${({ theme: { colors } }) => colors.grayscale.light};
  }

  svg {
    width: 32px;
    height: auto;
  }
`

export default function RenderSocialMedia({ data }: ISocialMedia) {
  return (
    <Container px={['0', '6em']} mb={['2em', '8em']}>
      <Flex
        justifyContent="center"
        backgroundColor="#FAFAFA"
        pt={['2em', '4em']}
        pb={['2em', '4em']}
      >
        <Box maxWidth="760px">
          <Flex justifyContent="center" flexDirection="column">
            <SubTitle textAlign="center" mb="1rem">
              {data.title}
            </SubTitle>

            <Description textAlign="center" color="greyMedium" mb="1rem">
              {data.description}
            </Description>

            <Flex mt="1.5em" width="100%" justifyContent="center">
              <Flex flexWrap="wrap" justifyContent="center">
                {data.socialMediaSites.map(socialRender)}
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Container>
  )
}

export interface IMenu {
  label: string
  src: string
}

function socialRender({ label, src }: IMenu) {
  if (!label || !src) {
    return null
  }

  return (
    <SocialLink key={label} to={src} aria-label={label}>
      {getSocialIcons(label)}
    </SocialLink>
  )
}
