import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import Link from '../../shared/Link'
import { Description, Title } from '../../shared/Typography'
import { ITextCentered } from '../types'

export default function querySectionSoftware({ data }: ITextCentered) {
  return (
    <Container px={['0', '6em']} mb={['2em', '5em']}>
      <Flex justifyContent="center" mb={['2em', '6em']}>
        <Box maxWidth="760px">
          <Flex justifyContent="center" flexDirection="column">
            <Title textAlign="center" mb="1rem">
              {data.title}
            </Title>

            <Description textAlign="center" color="greyMedium" mb="1rem">
              {data.description}
            </Description>

            {data.ctaLink && data.cta && (
              <Link
                color="blue"
                to={data.ctaLink}
                withIcon="arrow"
                childrenProps={{ justifyContent: 'center' }}
              >
                {data.cta}
              </Link>
            )}
          </Flex>
        </Box>
      </Flex>
    </Container>
  )
}
