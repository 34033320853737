import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image } from '../../shared/Image'
import {
  LinkVerticalSeparator,
  MainTitle,
  BlogText,
} from '../../shared/Typography'
import { IHeroNoBackground } from '../types'
import Link from '../../shared/Link'
import { isUrlExternal } from '../../../utils/isUrlExternal'

export default function renderHeroNoBackground({ data }: IHeroNoBackground) {
  const cols = ['100%', '50%']
  const pad = ['0', null, '0']

  const getArrowTypeIfLinkIsExternalOrInternal = (link: string) => {
    return isUrlExternal(link) ? 'arrowNortheast' : 'arrow'
  }

  return (
    <Container>
      <Flex flexWrap="wrap" px={[null, '0', '6em']} pb="4em">
        <Box pr={pad} width={cols} mt="4em">
          <Flex>
            <MainTitle mb="1rem">{data.title}</MainTitle>
          </Flex>
          <BlogText color="grey" mb="1rem">
            {data.description}
          </BlogText>
          <Box mt="1.5em">
            {data.listLinks &&
              data.listLinks.map(({ cta, ctaLink }) => {
                return (
                  <LinkVerticalSeparator color="greyLight" key={cta}>
                    {ctaLink && cta && (
                      <>
                        {Number.isNaN(Number(ctaLink)) ? (
                          <Link
                            color="blue"
                            withIcon={getArrowTypeIfLinkIsExternalOrInternal(
                              ctaLink
                            )}
                            title={cta}
                            aria-label={cta}
                            to={ctaLink}
                          >
                            {cta}
                          </Link>
                        ) : (
                          <Link
                            color="blue"
                            withIcon="arrowDown"
                            title={cta}
                            aria-label={cta}
                            onClick={() =>
                              window.scroll({
                                behavior: 'smooth',
                                left: 0,
                                top: Number(ctaLink),
                              })
                            }
                          >
                            {cta}
                          </Link>
                        )}
                      </>
                    )}
                  </LinkVerticalSeparator>
                )
              })}
          </Box>
        </Box>
        <Box width={cols}>
          {data.images &&
            data.images.map(({ image, alt }) => (
              <Image key={image} path={image} alt={alt} />
            ))}
        </Box>
      </Flex>
    </Container>
  )
}
