import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import { IBlogPosts } from '../types'
import { IRender } from './types'
import View from './view'

interface IReduce {
  prefers: Array<{ node: IQueryBlog }>
  others: Array<{ node: IQueryBlog }>
}

interface ICurr {
  node: IQueryBlog
}

export default function queryLatestPosts({ data }: IBlogPosts) {
  return (
    <StaticQuery
      query={graphql`
        query blogPosts {
          allMarkdownRemark(
            filter: { frontmatter: { category: { ne: null } } }
            sort: { fields: [frontmatter___date], order: DESC }
          ) {
            edges {
              node {
                excerpt(pruneLength: 100)
                fields {
                  slug
                  videoSnippet {
                    thumbnails {
                      high {
                        url
                      }
                    }
                    title
                    description
                  }
                }
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  title
                  category
                  author
                  videoId
                  hero {
                    childImageSharp {
                      fluid {
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark: { edges } }: IRender) => {
        let { categories, title = '' } = data

        categories = categories || []
        title = title || ''

        // reduce posts by category
        const { prefers, others } = edges.reduce(
          (prev: IReduce, curr: ICurr) => {
            const hasCategory = curr.node.frontmatter.category.some(r => {
              return categories.includes(r)
            })

            if (hasCategory) {
              prev.prefers.push(curr)
            }

            prev.others.push(curr)

            return prev
          },
          { prefers: [], others: [] }
        )

        // sort by date
        const posts: Array<{ node: IQueryBlog }> = [...prefers, ...others]
          .slice(0, 3)
          .sort((a, b) => {
            return (
              Number(new Date(b.node.frontmatter.date)) -
              Number(new Date(a.node.frontmatter.date))
            )
          })

        return <View message={title} posts={posts} />
      }}
    />
  )
}
